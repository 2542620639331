import React, { useState, useEffect } from 'react';
import { getPDFSettings, updatePDFSettings } from '../services/authService';
import { getUserProfile } from '../services/authService';
import PDFPreview from './PDFPreview';

const PDFSettings = () => {
  const [settings, setSettings] = useState({
    primaryColor: '#1E40AF',
    secondaryColor: '#F3F4F6',
    template: 'classic',
    showBankAccount: true,
    footerNotes: ''
  });
  
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [companyInfo, setCompanyInfo] = useState({
    company: 'Uw Bedrijf',
    name: 'Uw Naam',
    address: 'Voorbeeldstraat 123',
    postalCode: '1234 AB',
    city: 'Amsterdam',
    email: 'info@uwbedrijf.nl',
    website: 'www.uwbedrijf.nl'
  });
  
  // New state for collapsible sections on mobile
  const [mobileExpanded, setMobileExpanded] = useState({
    templates: true,
    colors: true,
    options: true,
    preview: false
  });
  
  // Toggle section on mobile
  const toggleMobileSection = (section) => {
    setMobileExpanded(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        
        // Fetch PDF settings
        const pdfData = await getPDFSettings();
        
        // Use the settings from the server or defaults
        setSettings({
          primaryColor: pdfData?.primaryColor || '#1E40AF',
          secondaryColor: pdfData?.secondaryColor || '#F3F4F6',
          template: pdfData?.template || 'classic',
          showBankAccount: pdfData?.showBankAccount !== undefined ? pdfData.showBankAccount : true,
          footerNotes: pdfData?.footerNotes || ''
        });
        
        // Fetch user profile for company info
        const userData = await getUserProfile();
        if (userData) {
          setCompanyInfo({
            company: userData.company || 'Uw Bedrijf',
            name: userData.name || 'Uw Naam', 
            address: userData.address || 'Voorbeeldstraat 123',
            postalCode: userData.postalCode || '1234 AB',
            city: userData.city || 'Amsterdam',
            email: userData.email || 'info@uwbedrijf.nl',
            website: userData.website || 'www.uwbedrijf.nl',
            kvkNumber: userData.kvkNumber || '',
            btwNumber: userData.btwNumber || ''
          });
        }
      } catch (err) {
        setError('Er is een fout opgetreden bij het ophalen van de instellingen.');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    
    setSettings(prev => ({
      ...prev,
      [name]: newValue
    }));
    
    // Clear any existing messages
    setError('');
    setSuccess('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      setIsSaving(true);
      setError('');
      setSuccess('');
      
      await updatePDFSettings(settings);
      
      // Scroll to the top of the form
      const formElement = document.getElementById('pdfSettingsForm');
      if (formElement) {
        formElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
      
      // Set success message
      setSuccess('PDF instellingen opgeslagen! Deze zullen worden toegepast op nieuwe PDF documenten.');
      
      // Add a flash animation to highlight the success message
      const successElement = document.getElementById('pdfSettingsSuccess');
      if (successElement) {
        successElement.classList.add('animate-pulse');
        setTimeout(() => {
          successElement.classList.remove('animate-pulse');
        }, 1500);
      }
      
      // Reset success message after 5 seconds
      setTimeout(() => {
        setSuccess('');
      }, 5000);
    } catch (err) {
      setError('Er is een fout opgetreden bij het opslaan van de instellingen.');
      console.error(err);
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-32">
        <div style={{ borderColor: 'var(--color-accent)' }} className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2"></div>
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: 'var(--color-bg-primary)' }} className="shadow-md rounded-lg overflow-hidden">
      <div style={{ backgroundColor: 'var(--color-bg-tertiary)', borderBottom: '1px solid var(--color-border)' }} className="p-4">
        <h3 style={{ color: 'var(--color-text-primary)' }} className="text-lg font-semibold">PDF Document Opmaak</h3>
      </div>
      
      <form id="pdfSettingsForm" onSubmit={handleSubmit} className="p-4 md:p-6">
        {error && (
          <div style={{ 
            backgroundColor: 'rgba(220, 38, 38, 0.1)',
            borderColor: 'var(--color-error)',
            color: 'var(--color-error)'
          }} className="border rounded mb-6 px-4 py-3">
            {error}
          </div>
        )}
        
        {success && (
          <div 
            id="pdfSettingsSuccess"
            style={{ 
              backgroundColor: 'rgba(16, 185, 129, 0.1)',
              borderColor: 'var(--color-success)',
              color: 'var(--color-success)',
              animation: 'slideDown 0.5s ease-out forwards'
            }} 
            className="border rounded mb-6 px-4 py-3 flex items-center"
          >
            <svg className="h-5 w-5 mr-2 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span>{success}</span>
            <style jsx>{`
              @keyframes slideDown {
                0% {
                  opacity: 0;
                  transform: translateY(-20px);
                }
                100% {
                  opacity: 1;
                  transform: translateY(0);
                }
              }
              .animate-pulse {
                animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1);
              }
              @keyframes pulse {
                0%, 100% {
                  opacity: 1;
                }
                50% {
                  opacity: 0.7;
                }
              }
            `}</style>
          </div>
        )}
        
        {/* Template Selection - with mobile collapsible header */}
        <div className="mb-6 border rounded-lg overflow-hidden" style={{ borderColor: 'var(--color-border)' }}>
          <div 
            className="flex items-center justify-between p-3 md:p-4 cursor-pointer md:cursor-default"
            style={{ backgroundColor: 'rgba(235, 245, 255, 0.2)' }}
            onClick={() => toggleMobileSection('templates')}
          >
            <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium">Document Template</label>
            <div className="md:hidden">
              {mobileExpanded.templates ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              )}
            </div>
          </div>
          
          {/* Template options - visible based on mobile expanded state */}
          <div className={`p-3 md:p-4 ${mobileExpanded.templates ? 'block' : 'hidden md:block'}`}>
            <div className="grid grid-cols-1 gap-3 md:grid-cols-3 md:gap-4">
              {/* Classic template option */}
              <div 
                className={`border rounded-lg p-3 cursor-pointer transition-all duration-200 ${settings.template === 'classic' ? 'ring-2 ring-blue-500' : ''}`}
                style={{
                  borderColor: settings.template === 'classic' ? 'var(--color-accent)' : 'var(--color-border)',
                  backgroundColor: settings.template === 'classic' ? 'var(--color-bg-tertiary)' : 'var(--color-bg-primary)'
                }}
                onClick={() => setSettings({...settings, template: 'classic'})}
              >
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center">
                    <input 
                      type="radio" 
                      id="classic" 
                      name="template" 
                      value="classic"
                      checked={settings.template === 'classic'}
                      onChange={handleChange}
                      className="h-5 w-5"
                      style={{ accentColor: 'var(--color-accent)' }}
                    />
                    <label htmlFor="classic" className="ml-2 text-sm font-medium" style={{ color: 'var(--color-text-primary)' }}>
                      Klassiek
                    </label>
                  </div>
                </div>
                <p style={{ color: 'var(--color-text-tertiary)' }} className="text-xs">
                  Traditionele layout met bedrijfs- en klantgegevens gescheiden aan weerszijden.
                </p>
              </div>
              
              {/* Modern template option */}
              <div 
                className={`border rounded-lg p-3 cursor-pointer transition-all duration-200 ${settings.template === 'modern' ? 'ring-2 ring-blue-500' : ''}`}
                style={{
                  borderColor: settings.template === 'modern' ? 'var(--color-accent)' : 'var(--color-border)',
                  backgroundColor: settings.template === 'modern' ? 'var(--color-bg-tertiary)' : 'var(--color-bg-primary)'
                }}
                onClick={() => setSettings({...settings, template: 'modern'})}
              >
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center">
                    <input 
                      type="radio" 
                      id="modern" 
                      name="template" 
                      value="modern"
                      checked={settings.template === 'modern'}
                      onChange={handleChange}
                      className="h-5 w-5"
                      style={{ accentColor: 'var(--color-accent)' }}
                    />
                    <label htmlFor="modern" className="ml-2 text-sm font-medium" style={{ color: 'var(--color-text-primary)' }}>
                      Modern
                    </label>
                  </div>
                </div>
                <p style={{ color: 'var(--color-text-tertiary)' }} className="text-xs">
                  Strakke opmaak met gekleurde accenten en duidelijke scheidslijnen.
                </p>
              </div>
              
              {/* Minimal template option */}
              <div 
                className={`border rounded-lg p-3 cursor-pointer transition-all duration-200 ${settings.template === 'minimal' ? 'ring-2 ring-blue-500' : ''}`}
                style={{
                  borderColor: settings.template === 'minimal' ? 'var(--color-accent)' : 'var(--color-border)',
                  backgroundColor: settings.template === 'minimal' ? 'var(--color-bg-tertiary)' : 'var(--color-bg-primary)'
                }}
                onClick={() => setSettings({...settings, template: 'minimal'})}
              >
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center">
                    <input 
                      type="radio" 
                      id="minimal" 
                      name="template" 
                      value="minimal"
                      checked={settings.template === 'minimal'}
                      onChange={handleChange}
                      className="h-5 w-5"
                      style={{ accentColor: 'var(--color-accent)' }}
                    />
                    <label htmlFor="minimal" className="ml-2 text-sm font-medium" style={{ color: 'var(--color-text-primary)' }}>
                      Minimaal
                    </label>
                  </div>
                </div>
                <p style={{ color: 'var(--color-text-tertiary)' }} className="text-xs">
                  Eenvoudige, ruimtebesparende layout met compacte informatie.
                </p>
              </div>
            </div>
          </div>
        </div>
        
        {/* Color Settings - with mobile collapsible header */}
        <div className="mb-6 border rounded-lg overflow-hidden" style={{ borderColor: 'var(--color-border)' }}>
          <div 
            className="flex items-center justify-between p-3 md:p-4 cursor-pointer md:cursor-default"
            style={{ backgroundColor: 'rgba(235, 245, 255, 0.2)' }}
            onClick={() => toggleMobileSection('colors')}
          >
            <h4 style={{ color: 'var(--color-text-primary)' }} className="text-md font-medium">Kleuren</h4>
            <div className="md:hidden">
              {mobileExpanded.colors ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              )}
            </div>
          </div>
          
          <div className={`p-3 md:p-4 ${mobileExpanded.colors ? 'block' : 'hidden md:block'}`}>
            <div className="space-y-4 md:space-y-0 md:grid md:grid-cols-2 md:gap-6">
              <div>
                <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">Primaire kleur</label>
                <div className="flex items-center">
                  <input 
                    type="color" 
                    id="primaryColor"
                    name="primaryColor"
                    value={settings.primaryColor}
                    onChange={handleChange}
                    className="h-10 w-10 rounded-md border cursor-pointer mr-2"
                    style={{ borderColor: 'var(--color-border)' }}
                  />
                  <input 
                    type="text" 
                    name="primaryColor"
                    value={settings.primaryColor}
                    onChange={handleChange}
                    className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    style={{ 
                      backgroundColor: 'var(--color-input-bg)',
                      borderColor: 'var(--color-input-border)',
                      color: 'var(--color-text-primary)'
                    }}
                  />
                </div>
                <p style={{ color: 'var(--color-text-tertiary)' }} className="mt-1 text-xs">
                  Wordt gebruikt voor titels, koppen en accentuering.
                </p>
              </div>
              
              <div>
                <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">Secundaire kleur</label>
                <div className="flex items-center">
                  <input 
                    type="color" 
                    id="secondaryColor"
                    name="secondaryColor"
                    value={settings.secondaryColor}
                    onChange={handleChange}
                    className="h-10 w-10 rounded-md border cursor-pointer mr-2"
                    style={{ borderColor: 'var(--color-border)' }}
                  />
                  <input 
                    type="text" 
                    name="secondaryColor"
                    value={settings.secondaryColor}
                    onChange={handleChange}
                    className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    style={{ 
                      backgroundColor: 'var(--color-input-bg)',
                      borderColor: 'var(--color-input-border)',
                      color: 'var(--color-text-primary)'
                    }}
                  />
                </div>
                <p style={{ color: 'var(--color-text-tertiary)' }} className="mt-1 text-xs">
                  Wordt gebruikt voor achtergronden, tabellen en subtiele elementen.
                </p>
              </div>
            </div>
          </div>
        </div>
        
        {/* Additional Settings - with mobile collapsible header */}
        <div className="mb-6 border rounded-lg overflow-hidden" style={{ borderColor: 'var(--color-border)' }}>
          <div 
            className="flex items-center justify-between p-3 md:p-4 cursor-pointer md:cursor-default"
            style={{ backgroundColor: 'rgba(235, 245, 255, 0.2)' }}
            onClick={() => toggleMobileSection('options')}
          >
            <h4 style={{ color: 'var(--color-text-primary)' }} className="text-md font-medium">Extra opties</h4>
            <div className="md:hidden">
              {mobileExpanded.options ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              )}
            </div>
          </div>
          
          <div className={`p-3 md:p-4 ${mobileExpanded.options ? 'block' : 'hidden md:block'}`}>
            <div className="mb-4">
              <div className="flex items-center">
                <input 
                  type="checkbox" 
                  id="showBankAccount"
                  name="showBankAccount"
                  checked={settings.showBankAccount}
                  onChange={handleChange}
                  style={{ accentColor: 'var(--color-accent)' }} 
                  className="h-5 w-5 mr-2" 
                />
                <label htmlFor="showBankAccount" style={{ color: 'var(--color-text-primary)' }} className="text-sm font-medium">
                  Toon bankgegevens op factuur
                </label>
              </div>
              <p style={{ color: 'var(--color-text-tertiary)' }} className="mt-1 text-xs ml-7">
                Voegt uw bankgegevens toe onderaan de factuur.
              </p>
            </div>
            
            <div>
              <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">Extra voettekst</label>
              <textarea 
                name="footerNotes"
                value={settings.footerNotes}
                onChange={handleChange}
                rows="3"
                placeholder="Bijv. betalingsvoorwaarden of bedanktekst"
                className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                style={{ 
                  backgroundColor: 'var(--color-input-bg)',
                  borderColor: 'var(--color-input-border)',
                  color: 'var(--color-text-primary)'
                }}
              ></textarea>
              <p style={{ color: 'var(--color-text-tertiary)' }} className="mt-1 text-xs">
                Deze tekst wordt toegevoegd aan de voettekst van de factuur.
              </p>
            </div>
          </div>
        </div>
        
        {/* PDF Preview Section - with mobile collapsible header */}
        <div className="mt-8 mb-6 border rounded-lg overflow-hidden" style={{ borderColor: 'var(--color-border)' }}>
          <div 
            className="flex items-center justify-between p-3 md:p-4 cursor-pointer"
            style={{ backgroundColor: 'rgba(235, 245, 255, 0.2)' }}
            onClick={() => toggleMobileSection('preview')}
          >
            <h4 style={{ color: 'var(--color-text-primary)' }} className="text-md font-medium">PDF Layout</h4>
            <div>
              {mobileExpanded.preview ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              )}
            </div>
          </div>
          
          <div className={`${mobileExpanded.preview ? 'block' : 'hidden'}`}>
            <div style={{ backgroundColor: 'var(--color-bg-tertiary)' }} className="p-2 md:p-4">
              <PDFPreview 
                templateStyle={settings.template}
                primaryColor={settings.primaryColor}
                secondaryColor={settings.secondaryColor}
                companyInfo={companyInfo}
                showBankAccount={settings.showBankAccount}
                footerNotes={settings.footerNotes}
              />
            </div>
            <p style={{ color: 'var(--color-text-tertiary)' }} className="text-xs text-center p-2 pb-4">
              Dit is een voorbeeld van hoe uw PDF eruit zal zien met de huidige instellingen.
            </p>
          </div>
        </div>
        
        <div className="mt-8 flex justify-end">
          <button 
            type="submit"
            disabled={isSaving}
            style={{ 
              backgroundColor: success ? 'var(--color-success)' : 'var(--color-accent)', 
              color: 'white',
              transition: 'all 0.3s ease'
            }}
            className={`px-6 py-3 rounded-md flex items-center ${success ? 'shadow-lg' : ''}`}
            onMouseOver={(e) => {
              if (!isSaving && !success) {
                e.currentTarget.style.backgroundColor = '#1a4ba8'; // Darker blue
                e.currentTarget.style.transform = 'translateY(-1px)';
              }
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = success ? 'var(--color-success)' : 'var(--color-accent)';
              e.currentTarget.style.transform = 'translateY(0)';
            }}
          >
            {isSaving ? (
              <>
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Opslaan...
              </>
            ) : success ? (
              <>
                <svg className="-ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                Opgeslagen!
              </>
            ) : (
              'Instellingen opslaan'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PDFSettings;
