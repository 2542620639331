// src/pages/CompanySettings.js
import React, { useState, useEffect, useRef } from 'react';
import { getUserProfile, updateUserProfile } from '../services/authService';
import { useNavigate } from 'react-router-dom';
import { ChevronUp, ChevronDown } from 'lucide-react';
import OrderNumberSettings from '../components/OrderNumberSettings';
import PDFSettings from '../components/PDFSettings';
import { refreshProfileCompletion } from '../components/ProfileCompletionCard';

// Additional CSS for animations
const formAnimationStyles = `
  @keyframes pulse {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.05); }
  }
  
  @keyframes highlightField {
    0% { background-color: rgba(59, 130, 246, 0.1); }
    100% { background-color: transparent; }
  }
  
  .form-section {
    transition: all 0.3s ease;
  }
  
  .form-section:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  
  .input-highlight {
    animation: highlightField 1.5s ease-out;
  }

  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .form-section {
    animation: slideDown 0.3s ease-out forwards;
  }
  
  /* Mobile touch improvements */
  @media (max-width: 768px) {
    input, select, textarea {
      min-height: 48px !important;
      font-size: 16px !important; /* Prevents iOS zoom on focus */
    }
    
    button {
      min-height: 44px;
    }
    
    /* Increase spacing between fields on mobile */
    .form-field {
      margin-bottom: 16px;
    }
    
    /* Improve checkbox and radio touch targets */
    input[type="checkbox"], 
    input[type="radio"] {
      min-width: 24px;
      min-height: 24px;
    }
    
    /* Improve dropdown touch targets */
    select {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
`;

const CompanySettings = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    address: '',
    postalCode: '',
    city: '',
    country: 'Nederland',
    phoneNumber: '',
    website: '',
    kvkNumber: '',
    btwNumber: '',
    bankAccount: '',
    bankAccountName: '',
    password: '',
    confirmPassword: ''
  });
  
  const [fieldErrors, setFieldErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [fieldFocus, setFieldFocus] = useState('');
  
  // Reference to store previous form data for comparison
  const prevFormDataRef = useRef({});
  
  // Collapsible sections state
  const [openSections, setOpenSections] = useState({
    basicInfo: true,
    companyInfo: true,
    contactInfo: true,
    taxInfo: true,
    bankInfo: true,
    securitySettings: true
  });
  
  useEffect(() => {
    // Keep a reference to the previous form data for comparison purposes
    prevFormDataRef.current = { ...formData };
  }, [formData]);
  
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setIsLoading(true);
        const userData = await getUserProfile();
        
        // Update form data with user profile
        setFormData({
          name: userData.name || '',
          email: userData.email || '',
          company: userData.company || '',
          address: userData.address || '',
          postalCode: userData.postalCode || '',
          city: userData.city || '',
          country: userData.country || 'Nederland',
          phoneNumber: userData.phoneNumber || '',
          website: userData.website || '',
          kvkNumber: userData.kvkNumber || '',
          btwNumber: userData.btwNumber || '',
          bankAccount: userData.bankAccount || '',
          bankAccountName: userData.bankAccountName || '',
          password: '',
          confirmPassword: ''
        });
        
        setError('');
      } catch (err) {
        setError('Er is een fout opgetreden bij het ophalen van uw gegevens.');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchUserData();
  }, []);
  
  // Validate Dutch postal code format
  const validateDutchPostalCode = (postalCode) => {
    // Remove spaces and convert to uppercase
    const formattedPostalCode = postalCode.replace(/\s/g, '').toUpperCase();
    
    // Dutch postal code format: 4 digits followed by 2 letters
    const dutchPostalCodeRegex = /^[1-9][0-9]{3}[A-Z]{2}$/;
    
    if (!dutchPostalCodeRegex.test(formattedPostalCode)) {
      return {
        isValid: false,
        message: 'Ongeldige postcode',
        formattedValue: formattedPostalCode
      };
    }
    
    // Format with a space for display (1234 AB)
    const displayPostalCode = formattedPostalCode.replace(/^([0-9]{4})([A-Z]{2})$/, '$1 $2');
    
    return {
      isValid: true,
      message: '',
      formattedValue: displayPostalCode
    };
  };
  
  // Validate phone number
  const validatePhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return { isValid: true, message: '' };
    
    // Remove all non-digit characters except +
    const cleanNumber = phoneNumber.replace(/[^\d+]/g, '');
    
    // Format to international format with spaces
    let formattedNumber = cleanNumber;
    
    // If it starts with 6, add +31 prefix
    if (cleanNumber.startsWith('6')) {
      formattedNumber = '+31' + cleanNumber;
    }
    // If it starts with 0, replace with +31
    else if (cleanNumber.startsWith('0')) {
      formattedNumber = '+31' + cleanNumber.slice(1);
    }
    
    // Check if it's a valid Dutch phone number after formatting
    const dutchPhoneRegex = /^(\+31)[1-9][0-9]{8}$/;
    
    if (!dutchPhoneRegex.test(formattedNumber)) {
      return {
        isValid: false,
        message: 'Voer een geldig Nederlands telefoonnummer in (bijv. +31 6 41141930)'
      };
    }
    
    // Add spaces after +31 and after the 6
    formattedNumber = formattedNumber.replace(/^(\+31)(\d)(\d{8})$/, '$1 $2 $3');
    
    return { 
      isValid: true, 
      message: '',
      formattedValue: formattedNumber
    };
  };
  
  // Validate KVK number
  const validateKvkNumber = (kvkNumber) => {
    // Remove spaces and dashes
    const formattedKvkNumber = kvkNumber.replace(/[\s-]/g, '');
    
    // Dutch KVK number is 8 digits
    const kvkRegex = /^[0-9]{8}$/;
    
    if (formattedKvkNumber && !kvkRegex.test(formattedKvkNumber)) {
      return {
        isValid: false,
        message: 'KVK nummer moet 8 cijfers bevatten',
        formattedValue: formattedKvkNumber
      };
    }
    
    return {
      isValid: true,
      message: '',
      formattedValue: formattedKvkNumber
    };
  };
  
  // Validate BTW number
  const validateBtwNumber = (btwNumber) => {
    // Remove spaces and dashes
    const formattedBtwNumber = btwNumber.replace(/[\s-]/g, '').toUpperCase();
    
    // Dutch BTW number format: NL123456789B01
    const btwRegex = /^NL[0-9]{9}B[0-9]{2}$/;
    
    if (formattedBtwNumber && !btwRegex.test(formattedBtwNumber)) {
      return {
        isValid: false,
        message: 'BTW nummer moet het formaat NL123456789B01 hebben',
        formattedValue: formattedBtwNumber
      };
    }
    
    return {
      isValid: true,
      message: '',
      formattedValue: formattedBtwNumber
    };
  };
  
  // Validate bank account (IBAN)
  const validateBankAccount = (bankAccount) => {
    // Remove spaces and convert to uppercase
    const formattedBankAccount = bankAccount.replace(/\s/g, '').toUpperCase();
    
    // Dutch IBAN format: NL91ABNA0417164300
    const ibanRegex = /^NL[0-9]{2}[A-Z]{4}[0-9]{10}$/;
    
    if (formattedBankAccount && !ibanRegex.test(formattedBankAccount)) {
      return {
        isValid: false,
        message: 'IBAN moet het formaat NL91ABNA0417164300 hebben',
        formattedValue: formattedBankAccount
      };
    }
    
    return {
      isValid: true,
      message: '',
      formattedValue: formattedBankAccount
    };
  };
  
  // Validate website URL
  const validateWebsite = (website) => {
    if (!website) return { isValid: true, message: '', formattedValue: '' };
    
    // Add http:// if not present
    let formattedWebsite = website;
    if (!formattedWebsite.startsWith('http://') && !formattedWebsite.startsWith('https://')) {
      formattedWebsite = 'https://' + formattedWebsite;
    }
    
    // Basic URL validation
    const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    
    if (!urlRegex.test(formattedWebsite)) {
      return {
        isValid: false,
        message: 'Ongeldige website URL',
        formattedValue: formattedWebsite
      };
    }
    
    return {
      isValid: true,
      message: '',
      formattedValue: formattedWebsite
    };
  };
  
  // Helper function to ensure field is visible when focused (not obscured by keyboard on mobile)
  const scrollToFocusedInput = (elementId) => {
    // Only run on mobile
    if (window.innerWidth <= 768) {
      setTimeout(() => {
        const element = document.getElementById(elementId);
        if (element) {
          // Calculate position to scroll to (element's position minus some space at top)
          const rect = element.getBoundingClientRect();
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          const targetPosition = rect.top + scrollTop - 120; // 120px space at top
          
          window.scrollTo({
            top: targetPosition,
            behavior: 'smooth'
          });
        }
      }, 300); // Small delay to ensure element has focused
    }
  };
  
  // Update the handleFocus function to use the scrolling helper
  const handleFocus = (fieldName) => {
    setFieldFocus(fieldName);
    scrollToFocusedInput(fieldName);
    
    // If section is collapsed, open it
    if (fieldName.includes('name') || fieldName.includes('email')) {
      setOpenSections(prev => ({ ...prev, basicInfo: true }));
    } else if (fieldName.includes('company') || fieldName.includes('address') || 
               fieldName.includes('postalCode') || fieldName.includes('city')) {
      setOpenSections(prev => ({ ...prev, companyInfo: true }));
    } else if (fieldName.includes('phone') || fieldName.includes('website')) {
      setOpenSections(prev => ({ ...prev, contactInfo: true }));
    } else if (fieldName.includes('kvk') || fieldName.includes('btw')) {
      setOpenSections(prev => ({ ...prev, taxInfo: true }));
    } else if (fieldName.includes('bank')) {
      setOpenSections(prev => ({ ...prev, bankInfo: true }));
    } else if (fieldName.includes('password')) {
      setOpenSections(prev => ({ ...prev, securitySettings: true }));
    }
  };
  
  // Handle field blur
  const handleBlur = () => {
    setFieldFocus('');
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    let errorMessage = '';
    
    // Apply specific validation based on field name
    switch (name) {
      case 'postalCode':
        const postalCodeValidation = validateDutchPostalCode(value);
        newValue = postalCodeValidation.formattedValue;
        errorMessage = postalCodeValidation.message;
        break;
      case 'phone':
        // Only allow numbers and + symbol
        newValue = value.replace(/[^\d+]/g, '');
        // Ensure + is only at the start
        if (newValue.indexOf('+') > 0) {
          newValue = newValue.replace('+', '');
        }
        // Limit length based on format
        if (newValue.startsWith('+')) {
          newValue = newValue.slice(0, 13); // +31 + 9 digits
        } else if (newValue.startsWith('0')) {
          newValue = newValue.slice(0, 10); // 0 + 9 digits
        } else if (newValue.startsWith('6')) {
          newValue = newValue.slice(0, 9); // 9 digits
        }
        const phoneValidation = validatePhoneNumber(newValue);
        newValue = phoneValidation.formattedValue || newValue;
        errorMessage = phoneValidation.message;
        break;
      case 'kvkNumber':
        // Only allow numbers
        newValue = value.replace(/[^\d]/g, '');
        // Limit to 8 digits
        newValue = newValue.slice(0, 8);
        const kvkValidation = validateKvkNumber(newValue);
        errorMessage = kvkValidation.message;
        break;
      case 'btwNumber':
        // Format as NL + numbers + B + numbers
        newValue = value.toUpperCase();
        // If user deleted everything, allow it
        if (newValue === '') break;
        
        // Always ensure it starts with NL
        if (!newValue.startsWith('NL')) {
          newValue = 'NL' + newValue.replace(/[^0-9B]/g, '');
        } else {
          // After NL, only allow numbers until B, then only numbers
          const parts = newValue.split('B');
          if (parts.length > 1) {
            // Has B character - format properly
            const prefix = parts[0].replace(/[^NL0-9]/g, '');
            const suffix = parts[1].replace(/[^0-9]/g, '').slice(0, 2);
            newValue = prefix + 'B' + suffix;
          } else {
            // No B yet, just numbers after NL
            newValue = 'NL' + parts[0].substring(2).replace(/[^0-9]/g, '');
          }
        }
        
        // Ensure proper format NL123456789B01
        if (newValue.length > 14) {
          newValue = newValue.slice(0, 14);
        }
        
        const btwValidation = validateBtwNumber(newValue);
        errorMessage = btwValidation.message;
        break;
      case 'bankAccount':
        const bankValidation = validateBankAccount(value);
        newValue = bankValidation.formattedValue;
        errorMessage = bankValidation.message;
        break;
      case 'website':
        const websiteValidation = validateWebsite(value);
        newValue = websiteValidation.formattedValue;
        errorMessage = websiteValidation.message;
        break;
      default:
        break;
    }
    
    // Update form data
    setFormData(prev => ({
      ...prev,
      [name]: newValue
    }));
    
    // Update field errors
    setFieldErrors(prev => ({
      ...prev,
      [name]: errorMessage
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate all fields before submission
    const newFieldErrors = {};
    
    // Check required fields
    if (!formData.name) newFieldErrors.name = 'Naam is verplicht';
    if (!formData.email) newFieldErrors.email = 'Email is verplicht';
    if (!formData.company) newFieldErrors.company = 'Bedrijfsnaam is verplicht';
    if (!formData.address) newFieldErrors.address = 'Adres is verplicht';
    if (!formData.city) newFieldErrors.city = 'Plaats is verplicht';
    
    // Validate postal code
    if (!formData.postalCode) {
      newFieldErrors.postalCode = 'Postcode is verplicht';
    } else {
      const postalCodeValidation = validateDutchPostalCode(formData.postalCode);
      if (!postalCodeValidation.isValid) {
        newFieldErrors.postalCode = postalCodeValidation.message;
      }
    }
    
    // Validate other fields if they have values
    if (formData.phone) {
      const phoneValidation = validatePhoneNumber(formData.phone);
      if (!phoneValidation.isValid) {
        newFieldErrors.phone = phoneValidation.message;
      }
    }
    
    if (formData.kvkNumber) {
      const kvkValidation = validateKvkNumber(formData.kvkNumber);
      if (!kvkValidation.isValid) {
        newFieldErrors.kvkNumber = kvkValidation.message;
      }
    }
    
    if (formData.btwNumber) {
      const btwValidation = validateBtwNumber(formData.btwNumber);
      if (!btwValidation.isValid) {
        newFieldErrors.btwNumber = btwValidation.message;
      }
    }
    
    if (formData.bankAccount) {
      const bankValidation = validateBankAccount(formData.bankAccount);
      if (!bankValidation.isValid) {
        newFieldErrors.bankAccount = bankValidation.message;
      }
    }
    
    if (formData.website) {
      const websiteValidation = validateWebsite(formData.website);
      if (!websiteValidation.isValid) {
        newFieldErrors.website = websiteValidation.message;
      }
    }
    
    // Check if there are any errors
    if (Object.keys(newFieldErrors).length > 0) {
      setFieldErrors(newFieldErrors);
      setError('Er zijn fouten in het formulier. Controleer de gemarkeerde velden.');
      return;
    }
    
    // Basic validation
    if (formData.password !== formData.confirmPassword) {
      setError('Wachtwoorden komen niet overeen.');
      return;
    }
    
    try {
      setIsSaving(true);
      setError('');
      setSuccess('');
      
      // Remove confirmPassword before sending to API
      const { confirmPassword, ...updateData } = formData;
      
      // Only include password if it's provided
      if (!updateData.password) {
        delete updateData.password;
      }
      
      await updateUserProfile(updateData);
      
      // Trigger profile completion card refresh
      refreshProfileCompletion();
      
      // Scroll to top of the page
      window.scrollTo({ top: 0, behavior: 'smooth' });
      
      // Set success message with animated effect
      setSuccess('Bedrijfsgegevens zijn succesvol bijgewerkt!');
      
      // Highlight the success notification
      const successElement = document.getElementById('companySettingsSuccess');
      if (successElement) {
        successElement.classList.add('animate-pulse');
        setTimeout(() => {
          successElement.classList.remove('animate-pulse');
        }, 1500);
      }
      
      // Reset success message after delay
      setTimeout(() => {
        setSuccess('');
      }, 5000);
      
      // Clear password fields
      setFormData(prev => ({
        ...prev,
        password: '',
        confirmPassword: ''
      }));
      
    } catch (err) {
      setError(err.message || 'Er is een fout opgetreden bij het opslaan van de gegevens.');
      console.error(err);
    } finally {
      setIsSaving(false);
    }
  };
  
  // Toggle section collapse
  const toggleSection = (section) => {
    setOpenSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };
  
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div style={{ borderColor: 'var(--color-accent)' }} className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2"></div>
      </div>
    );
  }
  
  return (
    <>
      <style jsx>{formAnimationStyles}</style>
      {/* Header - Hidden on mobile when scrolling to avoid interference with Layout's header behavior */}
      <header 
        style={{ 
          backgroundColor: 'var(--color-bg-primary)', 
          borderBottom: '1px solid var(--color-border)',
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
        }} 
        className="p-6 hidden md:block sticky top-0 z-10"
      >
        <div className="flex justify-between items-center">
          <div>
            <h2 style={{ color: 'var(--color-text-primary)' }} className="text-2xl font-bold mb-1">Bedrijfsinstellingen</h2>
            <p style={{ color: 'var(--color-text-secondary)' }} className="text-sm">
              Vul uw bedrijfsgegevens in om optimaal gebruik te maken van de applicatie
            </p>
          </div>
          <div>
            <button 
              type="button"
              onClick={() => document.getElementById('submitButton').click()}
              style={{ 
                backgroundColor: 'var(--color-accent)', 
                color: 'white',
                transition: 'all 0.2s ease',
                boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
              }}
              className="px-6 py-2 rounded-md font-medium hover:shadow-lg transform hover:-translate-y-0.5"
            >
              Wijzigingen opslaan
            </button>
          </div>
        </div>
      </header>
      
      {/* Mobile page title - Visible only on mobile */}
      <div className="md:hidden p-4 mt-2">
        <h2 style={{ color: 'var(--color-text-primary)' }} className="text-xl font-bold">Bedrijfsinstellingen</h2>
      </div>
      
      {/* Content Area */}
      <main style={{ backgroundColor: 'var(--color-bg-secondary)' }} className="flex-1 overflow-x-hidden overflow-y-auto p-6 md:pt-6 pt-0">
        {error && (
          <div 
            style={{ 
              backgroundColor: 'rgba(220, 38, 38, 0.05)',
              borderColor: 'var(--color-error)',
              color: 'var(--color-error)'
            }} 
            className="border-l-4 rounded-r mb-6 px-4 py-3 flex items-center shadow-sm"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-3" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
            </svg>
            {error}
          </div>
        )}
        
        {success && (
          <div 
            id="companySettingsSuccess"
            style={{ 
              backgroundColor: 'rgba(16, 185, 129, 0.05)',
              borderColor: 'var(--color-success)',
              color: 'var(--color-success)',
              animation: 'slideDown 0.5s ease-out forwards'
            }} 
            className="border-l-4 rounded-r mb-6 px-4 py-3 flex items-center shadow-sm"
          >
            <svg className="h-5 w-5 mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span>{success}</span>
            <style jsx>{`
              @keyframes slideDown {
                0% {
                  opacity: 0;
                  transform: translateY(-20px);
                }
                100% {
                  opacity: 1;
                  transform: translateY(0);
                }
              }
              .animate-pulse {
                animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1);
              }
              @keyframes pulse {
                0%, 100% {
                  opacity: 1;
                }
                50% {
                  opacity: 0.7;
                }
              }
            `}</style>
          </div>
        )}
        
        {/* Progress tracker - Only displayed when profile is not 100% complete */}
        {(() => {
          // Calculate completion percentage
          const requiredFields = ['name', 'email', 'company', 'address', 'postalCode', 'city', 'kvkNumber', 'btwNumber'];
          const filledFields = requiredFields.filter(field => formData[field] && formData[field].trim() !== '').length;
          const completionPercentage = Math.round((filledFields / requiredFields.length) * 100);
          
          // Only show progress tracker if profile is not 100% complete
          if (completionPercentage < 100) {
            return (
              <div className="mb-8 px-2">
                <div className="flex justify-between mb-1">
                  <span style={{ color: 'var(--color-text-secondary)' }} className="text-xs font-medium">Profielvoltooid</span>
                  <span style={{ color: 'var(--color-text-primary)' }} className="text-xs font-medium">
                    {completionPercentage}%
                  </span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 mb-4">
                  <div 
                    className="h-2.5 rounded-full transition-all duration-500" 
                    style={{ 
                      width: `${completionPercentage}%`,
                      backgroundColor: 'var(--color-accent)'
                    }}
                  ></div>
                </div>
              </div>
            );
          }
          return null; // Return null if profile is 100% complete
        })()}
        
        {/* Profile Completion Banner - Only displayed when profile is not 100% complete */}
        {(() => {
          // Calculate completion percentage
          const requiredFields = ['name', 'email', 'company', 'address', 'postalCode', 'city', 'kvkNumber', 'btwNumber'];
          const filledFields = requiredFields.filter(field => formData[field] && formData[field].trim() !== '').length;
          const completionPercentage = Math.round((filledFields / requiredFields.length) * 100);
          
          // Only show banner if profile is not 100% complete
          if (completionPercentage < 100) {
            return (
              <div 
                className="rounded-lg shadow-md mb-6 overflow-hidden transition-all duration-300 animate-slideDown"
                style={{ 
                  backgroundColor: 'rgba(219, 234, 254, 0.4)',
                  borderLeft: '4px solid var(--color-accent)'
                }}
              >
                <div className="p-4">
                  <h3 className="font-bold text-lg mb-2" style={{ color: 'var(--color-text-primary)' }}>
                    Voltooi uw bedrijfsprofiel
                  </h3>
                  <p className="mb-3" style={{ color: 'var(--color-text-secondary)' }}>
                    Om optimaal gebruik te kunnen maken van alle functies van de applicatie, vul uw bedrijfsgegevens volledig in. 
                    De velden gemarkeerd met een <span className="text-red-500">*</span> zijn belangrijk voor het genereren van correcte inkooporders.
                  </p>
                </div>
              </div>
            );
          }
          return null; // Return null if profile is 100% complete
        })()}
        
        <div 
          style={{ 
            backgroundColor: 'var(--color-bg-primary)', 
            borderColor: 'var(--color-border)' 
          }} 
          className="shadow-lg rounded-lg overflow-hidden mb-6 transition-all duration-300 hover:shadow-xl"
        >
          <div 
            style={{ 
              backgroundColor: 'var(--color-bg-tertiary)',
              borderBottom: '1px solid var(--color-border)'
            }} 
            className="p-5 flex items-center"
          >
            <div className="bg-blue-100 p-2 rounded-full mr-3" style={{ color: 'var(--color-accent)' }}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
              </svg>
            </div>
            <div>
              <h3 style={{ color: 'var(--color-text-primary)' }} className="text-lg font-semibold mb-0.5">Bedrijfsgegevens</h3>
              <p style={{ color: 'var(--color-text-secondary)' }} className="text-sm">Deze informatie wordt gebruikt in de gegenereerde PDF documenten.</p>
            </div>
          </div>
          
          <form onSubmit={handleSubmit} className="p-5">
            {/* Basic Information */}
            <div className="mb-6 form-section rounded-lg border overflow-hidden" 
                 style={{ borderColor: 'var(--color-border)' }}>
              <div 
                className="flex items-center justify-between p-4 cursor-pointer"
                style={{ backgroundColor: 'rgba(235, 245, 255, 0.2)' }}
                onClick={() => toggleSection('basicInfo')}
              >
                <div className="flex items-center">
                  <div className="h-8 w-8 rounded-full bg-blue-50 flex items-center justify-center mr-3" style={{ color: 'var(--color-accent)' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                  </div>
                  <h4 style={{ color: 'var(--color-text-primary)' }} className="text-md font-medium">Algemene informatie</h4>
                </div>
                <div style={{ color: 'var(--color-text-secondary)' }}>
                  {openSections.basicInfo ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </div>
              </div>
              
              {openSections.basicInfo && (
                <div className="p-4" style={{ backgroundColor: 'rgba(235, 245, 255, 0.1)' }}>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="group">
                      <label 
                        style={{ 
                          color: fieldFocus === 'name' ? 'var(--color-accent)' : 'var(--color-text-secondary)',
                          transition: 'color 0.3s ease'
                        }} 
                        className="block text-sm font-medium mb-1 transition-colors duration-200 group-hover:text-blue-500"
                      >
                        Naam
                      </label>
                      <div className="relative">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          onFocus={() => handleFocus('name')}
                          onBlur={handleBlur}
                          required
                          className={`w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 
                            ${fieldFocus === 'name' ? 'input-highlight' : ''}`
                          }
                          style={{ 
                            backgroundColor: 'var(--color-input-bg)',
                            borderColor: fieldFocus === 'name' ? 'var(--color-accent)' : 'var(--color-input-border)',
                            color: 'var(--color-text-primary)',
                            boxShadow: fieldFocus === 'name' ? '0 0 0 3px rgba(59, 130, 246, 0.3)' : 'none'
                          }}
                        />
                        <div className={`absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none transition-opacity duration-200 ${fieldFocus === 'name' ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" style={{ color: 'var(--color-accent)' }} viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="group">
                      <label 
                        style={{ 
                          color: fieldFocus === 'email' ? 'var(--color-accent)' : 'var(--color-text-secondary)',
                          transition: 'color 0.3s ease'
                        }} 
                        className="block text-sm font-medium mb-1 transition-colors duration-200 group-hover:text-blue-500"
                      >
                        Email
                      </label>
                      <div className="relative">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          onFocus={() => handleFocus('email')}
                          onBlur={handleBlur}
                          required
                          className={`w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 
                            ${fieldFocus === 'email' ? 'input-highlight' : ''}`
                          }
                          style={{ 
                            backgroundColor: 'var(--color-input-bg)',
                            borderColor: fieldFocus === 'email' ? 'var(--color-accent)' : 'var(--color-input-border)',
                            color: 'var(--color-text-primary)',
                            boxShadow: fieldFocus === 'email' ? '0 0 0 3px rgba(59, 130, 246, 0.3)' : 'none'
                          }}
                        />
                        <div className={`absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none transition-opacity duration-200 ${fieldFocus === 'email' ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" style={{ color: 'var(--color-accent)' }} viewBox="0 0 20 20" fill="currentColor">
                            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                          </svg>
                        </div>
                      </div>
                      <div className="mt-1 text-xs opacity-0 group-hover:opacity-100 transition-opacity duration-200" style={{ color: 'var(--color-text-secondary)' }}>
                        Dit e-mailadres wordt gebruikt voor communicatie
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            
            {/* Company Information */}
            <div className="mb-6 form-section rounded-lg border overflow-hidden" 
                 style={{ borderColor: 'var(--color-border)' }}>
              <div 
                className="flex items-center justify-between p-4 cursor-pointer"
                style={{ backgroundColor: 'rgba(235, 245, 255, 0.2)' }}
                onClick={() => toggleSection('companyInfo')}
              >
                <div className="flex items-center">
                  <div className="h-8 w-8 rounded-full bg-blue-50 flex items-center justify-center mr-3" style={{ color: 'var(--color-accent)' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                    </svg>
                  </div>
                  <h4 style={{ color: 'var(--color-text-primary)' }} className="text-md font-medium">Bedrijfsgegevens</h4>
                </div>
                <div style={{ color: 'var(--color-text-secondary)' }}>
                  {openSections.companyInfo ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </div>
              </div>
              
              {openSections.companyInfo && (
                <div className="p-4" style={{ backgroundColor: 'rgba(235, 245, 255, 0.1)' }}>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">Bedrijfsnaam</label>
                      <div className="relative">
                        <input
                          type="text"
                          id="company"
                          name="company"
                          value={formData.company}
                          onChange={handleChange}
                          onFocus={() => handleFocus('company')}
                          onBlur={handleBlur}
                          required
                          className={`w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent 
                            ${fieldFocus === 'company' ? 'input-highlight' : ''}`
                          }
                          style={{ 
                            backgroundColor: 'var(--color-input-bg)',
                            borderColor: fieldFocus === 'company' ? 'var(--color-accent)' : 'var(--color-input-border)',
                            color: 'var(--color-text-primary)',
                            boxShadow: fieldFocus === 'company' ? '0 0 0 3px rgba(59, 130, 246, 0.3)' : 'none'
                          }}
                        />
                        <div className={`absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none transition-opacity duration-200 ${fieldFocus === 'company' ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" style={{ color: 'var(--color-accent)' }} viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clipRule="evenodd" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    
                    <div>
                      <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">
                        Adres <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        id="address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        className={`w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${fieldErrors.address ? 'border-red-300' : ''}`}
                        style={{ 
                          backgroundColor: 'var(--color-input-bg)',
                          borderColor: fieldErrors.address ? 'var(--color-error)' : 'var(--color-input-border)',
                          color: 'var(--color-text-primary)'
                        }}
                      />
                      {fieldErrors.address && (
                        <p className="mt-1 text-xs text-red-500">
                          {fieldErrors.address}
                        </p>
                      )}
                    </div>
                  </div>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                    <div>
                      <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">
                        Postcode <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        id="postalCode"
                        name="postalCode"
                        value={formData.postalCode}
                        onChange={handleChange}
                        maxLength={7}
                        placeholder="1234 AB"
                        className={`w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${fieldErrors.postalCode ? 'border-red-300' : ''}`}
                        style={{ 
                          backgroundColor: 'var(--color-input-bg)',
                          borderColor: fieldErrors.postalCode ? 'var(--color-error)' : 'var(--color-input-border)',
                          color: 'var(--color-text-primary)'
                        }}
                      />
                      {fieldErrors.postalCode && (
                        <p className="mt-1 text-xs text-red-500">
                          {fieldErrors.postalCode}
                        </p>
                      )}
                    </div>
                    <div>
                      <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">
                        Plaats <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        id="city"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        className={`w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${fieldErrors.city ? 'border-red-300' : ''}`}
                        style={{ 
                          backgroundColor: 'var(--color-input-bg)',
                          borderColor: fieldErrors.city ? 'var(--color-error)' : 'var(--color-input-border)',
                          color: 'var(--color-text-primary)'
                        }}
                      />
                      {fieldErrors.city && (
                        <p className="mt-1 text-xs text-red-500">
                          {fieldErrors.city}
                        </p>
                      )}
                    </div>
                  </div>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                    <div>
                      <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">Land</label>
                      <select
                        id="country"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        style={{ 
                          backgroundColor: 'var(--color-input-bg)',
                          borderColor: 'var(--color-input-border)',
                          color: 'var(--color-text-primary)'
                        }}
                      >
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Albanië">Albanië</option>
                        <option value="Algerije">Algerije</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Argentinië">Argentinië</option>
                        <option value="Australië">Australië</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="België">België</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Brazilië">Brazilië</option>
                        <option value="Brunei">Brunei</option>
                        <option value="Bulgarije">Bulgarije</option>
                        <option value="Cambodja">Cambodja</option>
                        <option value="Canada">Canada</option>
                        <option value="Chili">Chili</option>
                        <option value="China">China</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Denemarken">Denemarken</option>
                        <option value="Duitsland">Duitsland</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypte">Egypte</option>
                        <option value="Estland">Estland</option>
                        <option value="Ethiopië">Ethiopië</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Filipijnen">Filipijnen</option>
                        <option value="Finland">Finland</option>
                        <option value="Frankrijk">Frankrijk</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Griekenland">Griekenland</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Hongarije">Hongarije</option>
                        <option value="Ierland">Ierland</option>
                        <option value="India">India</option>
                        <option value="Indonesië">Indonesië</option>
                        <option value="Irak">Irak</option>
                        <option value="Iran">Iran</option>
                        <option value="Israël">Israël</option>
                        <option value="Italië">Italië</option>
                        <option value="Ivoorkust">Ivoorkust</option>
                        <option value="Ijsland">Ijsland</option>
                        <option value="Japan">Japan</option>
                        <option value="Jemen">Jemen</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kenia">Kenia</option>
                        <option value="Kroatië">Kroatië</option>
                        <option value="Laos">Laos</option>
                        <option value="Letland">Letland</option>
                        <option value="Libanon">Libanon</option>
                        <option value="Libië">Libië</option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Litouwen">Litouwen</option>
                        <option value="Luxemburg">Luxemburg</option>
                        <option value="Maleisië">Maleisië</option>
                        <option value="Malta">Malta</option>
                        <option value="Marokko">Marokko</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Namibië">Namibië</option>
                        <option value="Nederland">Nederland</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Nieuw-Zeeland">Nieuw-Zeeland</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Noorwegen">Noorwegen</option>
                        <option value="Oeganda">Oeganda</option>
                        <option value="Oekraïne">Oekraïne</option>
                        <option value="Oman">Oman</option>
                        <option value="Oostenrijk">Oostenrijk</option>
                        <option value="Oost-Timor">Oost-Timor</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Panama">Panama</option>
                        <option value="Papoea-Nieuw-Guinea">Papoea-Nieuw-Guinea</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Polen">Polen</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Roemenië">Roemenië</option>
                        <option value="Rusland">Rusland</option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Saoedi-Arabië">Saoedi-Arabië</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Servië">Servië</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slowakije">Slowakije</option>
                        <option value="Slovenië">Slovenië</option>
                        <option value="Soedan">Soedan</option>
                        <option value="Somalië">Somalië</option>
                        <option value="Spanje">Spanje</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Syrië">Syrië</option>
                        <option value="Tanzania">Tanzania</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Tsjechië">Tsjechië</option>
                        <option value="Tunesië">Tunesië</option>
                        <option value="Turkije">Turkije</option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Vaticaanstad">Vaticaanstad</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Verenigde Staten">Verenigde Staten</option>
                        <option value="Verenigd Koninkrijk">Verenigd Koninkrijk</option>
                        <option value="Vietnam">Vietnam</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                        <option value="Zuid-Afrika">Zuid-Afrika</option>
                        <option value="Zuid-Korea">Zuid-Korea</option>
                        <option value="Zweden">Zweden</option>
                        <option value="Zwitserland">Zwitserland</option>
                        <option value="Andere">Andere</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
            </div>
            
            {/* Contact Information */}
            <div className="mb-6 form-section rounded-lg border overflow-hidden" 
                 style={{ borderColor: 'var(--color-border)' }}>
              <div 
                className="flex items-center justify-between p-4 cursor-pointer"
                style={{ backgroundColor: 'rgba(235, 245, 255, 0.2)' }}
                onClick={() => toggleSection('contactInfo')}
              >
                <div className="flex items-center">
                  <div className="h-8 w-8 rounded-full bg-blue-50 flex items-center justify-center mr-3" style={{ color: 'var(--color-accent)' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                    </svg>
                  </div>
                  <h4 style={{ color: 'var(--color-text-primary)' }} className="text-md font-medium">Contactgegevens</h4>
                </div>
                <div style={{ color: 'var(--color-text-secondary)' }}>
                  {openSections.contactInfo ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </div>
              </div>
              
              {openSections.contactInfo && (
                <div className="p-4" style={{ backgroundColor: 'rgba(235, 245, 255, 0.1)' }}>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">Telefoonnummer</label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className={`w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${fieldErrors.phone ? 'border-red-300' : ''}`}
                        style={{ 
                          backgroundColor: 'var(--color-input-bg)',
                          borderColor: fieldErrors.phone ? 'var(--color-error)' : 'var(--color-input-border)',
                          color: 'var(--color-text-primary)'
                        }}
                      />
                      {fieldErrors.phone && (
                        <p className="mt-1 text-xs text-red-500">
                          {fieldErrors.phone}
                        </p>
                      )}
                    </div>
                    <div>
                      <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">Website</label>
                      <input
                        type="url"
                        id="website"
                        name="website"
                        value={formData.website}
                        onChange={handleChange}
                        className={`w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${fieldErrors.website ? 'border-red-300' : ''}`}
                        style={{ 
                          backgroundColor: 'var(--color-input-bg)',
                          borderColor: fieldErrors.website ? 'var(--color-error)' : 'var(--color-input-border)',
                          color: 'var(--color-text-primary)'
                        }}
                      />
                      {fieldErrors.website && (
                        <p className="mt-1 text-xs text-red-500">
                          {fieldErrors.website}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            
            {/* Tax Information */}
            <div className="mb-6 form-section rounded-lg border overflow-hidden" 
                 style={{ borderColor: 'var(--color-border)' }}>
              <div 
                className="flex items-center justify-between p-4 cursor-pointer"
                style={{ backgroundColor: 'rgba(235, 245, 255, 0.2)' }}
                onClick={() => toggleSection('taxInfo')}
              >
                <div className="flex items-center">
                  <div className="h-8 w-8 rounded-full bg-blue-50 flex items-center justify-center mr-3" style={{ color: 'var(--color-accent)' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                    </svg>
                  </div>
                  <h4 style={{ color: 'var(--color-text-primary)' }} className="text-md font-medium">Belastinggegevens</h4>
                </div>
                <div style={{ color: 'var(--color-text-secondary)' }}>
                  {openSections.taxInfo ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </div>
              </div>
              
              {openSections.taxInfo && (
                <div className="p-4" style={{ backgroundColor: 'rgba(235, 245, 255, 0.1)' }}>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">KVK-nummer</label>
                      <input
                        type="text"
                        id="kvkNumber"
                        name="kvkNumber"
                        value={formData.kvkNumber}
                        onChange={handleChange}
                        maxLength={8}
                        placeholder="12345678"
                        className={`w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${fieldErrors.kvkNumber ? 'border-red-300' : ''}`}
                        style={{ 
                          backgroundColor: 'var(--color-input-bg)',
                          borderColor: fieldErrors.kvkNumber ? 'var(--color-error)' : 'var(--color-input-border)',
                          color: 'var(--color-text-primary)'
                        }}
                      />
                      {fieldErrors.kvkNumber && (
                        <p className="mt-1 text-xs text-red-500">
                          {fieldErrors.kvkNumber}
                        </p>
                      )}
                    </div>
                    <div>
                      <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">BTW-nummer</label>
                      <input
                        type="text"
                        id="btwNumber"
                        name="btwNumber"
                        value={formData.btwNumber}
                        onChange={handleChange}
                        maxLength={14}
                        placeholder="NL123456789B01"
                        className={`w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${fieldErrors.btwNumber ? 'border-red-300' : ''}`}
                        style={{ 
                          backgroundColor: 'var(--color-input-bg)',
                          borderColor: fieldErrors.btwNumber ? 'var(--color-error)' : 'var(--color-input-border)',
                          color: 'var(--color-text-primary)'
                        }}
                      />
                      {fieldErrors.btwNumber && (
                        <p className="mt-1 text-xs text-red-500">
                          {fieldErrors.btwNumber}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            
            {/* Banking Information */}
            <div className="mb-6 form-section rounded-lg border overflow-hidden" 
                 style={{ borderColor: 'var(--color-border)' }}>
              <div 
                className="flex items-center justify-between p-4 cursor-pointer"
                style={{ backgroundColor: 'rgba(235, 245, 255, 0.2)' }}
                onClick={() => toggleSection('bankInfo')}
              >
                <div className="flex items-center">
                  <div className="h-8 w-8 rounded-full bg-blue-50 flex items-center justify-center mr-3" style={{ color: 'var(--color-accent)' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                    </svg>
                  </div>
                  <h4 style={{ color: 'var(--color-text-primary)' }} className="text-md font-medium">Bankgegevens</h4>
                </div>
                <div style={{ color: 'var(--color-text-secondary)' }}>
                  {openSections.bankInfo ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </div>
              </div>
              
              {openSections.bankInfo && (
                <div className="p-4" style={{ backgroundColor: 'rgba(235, 245, 255, 0.1)' }}>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">IBAN</label>
                      <input
                        type="text"
                        id="bankAccount"
                        name="bankAccount"
                        value={formData.bankAccount}
                        onChange={handleChange}
                        className={`w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${fieldErrors.bankAccount ? 'border-red-300' : ''}`}
                        style={{ 
                          backgroundColor: 'var(--color-input-bg)',
                          borderColor: fieldErrors.bankAccount ? 'var(--color-error)' : 'var(--color-input-border)',
                          color: 'var(--color-text-primary)'
                        }}
                      />
                      {fieldErrors.bankAccount && (
                        <p className="mt-1 text-xs text-red-500">
                          {fieldErrors.bankAccount}
                        </p>
                      )}
                    </div>
                    <div>
                      <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">Ten name van</label>
                      <input
                        type="text"
                        id="bankAccountName"
                        name="bankAccountName"
                        value={formData.bankAccountName}
                        onChange={handleChange}
                        className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        style={{ 
                          backgroundColor: 'var(--color-input-bg)',
                          borderColor: 'var(--color-input-border)',
                          color: 'var(--color-text-primary)'
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            
            {/* Security Settings */}
            <div className="mb-6 form-section rounded-lg border overflow-hidden" 
                 style={{ borderColor: 'var(--color-border)' }}>
              <div 
                className="flex items-center justify-between p-4 cursor-pointer"
                style={{ backgroundColor: 'rgba(235, 245, 255, 0.2)' }}
                onClick={() => toggleSection('securitySettings')}
              >
                <div className="flex items-center">
                  <div className="h-8 w-8 rounded-full bg-blue-50 flex items-center justify-center mr-3" style={{ color: 'var(--color-accent)' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                    </svg>
                  </div>
                  <h4 style={{ color: 'var(--color-text-primary)' }} className="text-md font-medium">Beveiligingsinstellingen</h4>
                </div>
                <div style={{ color: 'var(--color-text-secondary)' }}>
                  {openSections.securitySettings ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </div>
              </div>
              
              {openSections.securitySettings && (
                <div className="p-4" style={{ backgroundColor: 'rgba(235, 245, 255, 0.1)' }}>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">Nieuw wachtwoord</label>
                      <input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        style={{ 
                          backgroundColor: 'var(--color-input-bg)',
                          borderColor: 'var(--color-input-border)',
                          color: 'var(--color-text-primary)'
                        }}
                      />
                    </div>
                    <div>
                      <label style={{ color: 'var(--color-text-secondary)' }} className="block text-sm font-medium mb-1">Bevestig wachtwoord</label>
                      <input
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        className="w-full border rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        style={{ 
                          backgroundColor: 'var(--color-input-bg)',
                          borderColor: 'var(--color-input-border)',
                          color: 'var(--color-text-primary)'
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            
            <div className="mt-8 flex justify-end">
              <button 
                id="submitButton"
                type="submit"
                disabled={isSaving}
                style={{ 
                  backgroundColor: success ? 'var(--color-success)' : 'var(--color-accent)', 
                  color: 'white',
                  transition: 'all 0.3s ease',
                  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
                }}
                className={`px-6 py-3 rounded-md flex items-center ${success ? 'shadow-lg' : ''} transform hover:-translate-y-1 hover:shadow-lg`}
              >
                {isSaving ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <span className="font-medium">Opslaan...</span>
                  </>
                ) : success ? (
                  <>
                    <svg className="-ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="font-medium">Opgeslagen!</span>
                  </>
                ) : (
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
                    </svg>
                    <span className="font-medium">Gegevens opslaan</span>
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
        
        {/* Scroll to top button */}
        <button
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          className="fixed md:bottom-8 bottom-20 right-8 p-3 rounded-full shadow-lg z-20 transition-all duration-300 hover:-translate-y-1 hover:shadow-xl"
          style={{ 
            backgroundColor: 'var(--color-accent)', 
            color: 'white',
            opacity: '0.8',
            transform: 'scale(0.9)'
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.opacity = '1';
            e.currentTarget.style.transform = 'scale(1)';
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.opacity = '0.8';
            e.currentTarget.style.transform = 'scale(0.9)';
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 11l7-7 7 7M5 19l7-7 7 7" />
          </svg>
        </button>
        
        {/* Order Number Settings */}
        <div className="mt-6 mb-6">
          <OrderNumberSettings />
        </div>
        
        {/* PDF Settings */}
        <div className="mt-6 mb-6">
          <PDFSettings />
        </div>
      </main>
    </>
  );
};

export default CompanySettings;