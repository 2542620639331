// src/components/Layout.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Home, FileText, Users, LogOut, Settings, User, Menu, X } from 'lucide-react';
import { logout, getCurrentUser } from '../services/authService';
import ThemeToggle from './ThemeToggle';

const Layout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = getCurrentUser();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  
  // State for header visibility on mobile
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  
  const handleLogout = () => {
    logout();
    navigate('/login');
  };
  
  const toggleUserMenu = () => {
    setShowUserMenu(!showUserMenu);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  
  // Close mobile menu when navigation occurs
  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location.pathname]);

  // Close mobile menu on window resize (if screen becomes larger)
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  // Control header visibility based on scroll direction
  useEffect(() => {
    const controlHeader = () => {
      // Only apply this behavior on mobile
      if (window.innerWidth >= 768) return;
      
      const currentScrollY = window.scrollY;
      
      // Show header when scrolling up or at the top of the page
      if (currentScrollY <= 10 || currentScrollY < lastScrollY) {
        setIsHeaderVisible(true);
      } 
      // Hide header when scrolling down
      else if (currentScrollY > lastScrollY && currentScrollY > 50) {
        setIsHeaderVisible(false);
        // Close mobile menu if open when scrolling down
        if (isMobileMenuOpen) {
          setIsMobileMenuOpen(false);
        }
      }
      
      setLastScrollY(currentScrollY);
    };
    
    window.addEventListener('scroll', controlHeader);
    return () => {
      window.removeEventListener('scroll', controlHeader);
    };
  }, [lastScrollY, isMobileMenuOpen]);
  
  return (
    <div className="flex flex-col md:flex-row h-screen" style={{ backgroundColor: 'var(--color-bg-primary)' }}>
      {/* Mobile Header with Menu Toggle - Hidden when scrolling down */}
      <div 
        className={`md:hidden shadow-sm px-4 py-3 flex justify-between items-center fixed top-0 left-0 right-0 z-20 transition-transform duration-300 ${!isHeaderVisible ? '-translate-y-full' : 'translate-y-0'}`}
        style={{ backgroundColor: 'var(--color-sidebar)', borderBottom: '1px solid var(--color-border)' }}
      >
        <div className="flex items-center">
          <div className="w-8 h-8 bg-blue-600 rounded-full flex items-center justify-center text-white text-lg font-bold mr-2">
            {user?.company?.charAt(0) || 'U'}
          </div>
          <h1 className="text-lg font-bold truncate max-w-[180px]" style={{ color: 'var(--color-text-primary)' }}>
            {user?.company || 'Inkooporders App'}
          </h1>
        </div>
        <button 
          onClick={toggleMobileMenu}
          className="p-1 rounded-md"
          style={{ color: 'var(--color-text-primary)' }}
        >
          {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>
      
      {/* Add spacer for fixed header on mobile */}
      <div className="md:hidden h-[56px]"></div>
      
      {/* Sidebar - Hidden on mobile unless menu is open */}
      <div className={`${isMobileMenuOpen ? 'block' : 'hidden'} md:block w-full md:w-64 shadow-md p-4 flex flex-col h-[calc(100vh-60px)] md:h-screen fixed top-[56px] md:static z-10 left-0 right-0 md:z-auto`} 
           style={{ 
             backgroundColor: 'var(--color-sidebar)', 
             borderRight: '1px solid var(--color-border)'
           }}>
        <div className="flex flex-col h-full">
          <div className="hidden md:flex mb-8 items-center">
            <div className="w-10 h-10 bg-blue-600 rounded-full flex items-center justify-center text-white text-xl font-bold mr-3">
              {user?.company?.charAt(0) || 'U'}
            </div>
            <h1 className="text-xl font-bold" style={{ color: 'var(--color-text-primary)' }}>
              {user?.company || 'Inkooporders App'}
            </h1>
          </div>
          
          <div className="mb-6">
            <p style={{ color: 'var(--color-text-tertiary)' }} className="text-sm">Ingelogd als:</p>
            <p style={{ color: 'var(--color-text-primary)' }} className="font-medium">{user?.name}</p>
            <p style={{ color: 'var(--color-text-tertiary)' }} className="text-sm">{user?.company}</p>
          </div>
          
          <nav className="space-y-2 flex-grow-0">
            <SidebarItem 
              to="/" 
              icon={<Home size={20} />} 
              label="Dashboard" 
              active={location.pathname === '/'} 
            />
            <SidebarItem 
              to="/inkooporders" 
              icon={<FileText size={20} />} 
              label="Inkooporders" 
              active={location.pathname === '/inkooporders' || location.pathname.startsWith('/inkooporders/')} 
            />
            <SidebarItem 
              to="/klanten" 
              icon={<Users size={20} />} 
              label="Klanten" 
              active={location.pathname === '/klanten'} 
            />
            <SidebarItem 
              to="/bedrijfsinstellingen" 
              icon={<Settings size={20} />} 
              label="Bedrijfsinstellingen" 
              active={location.pathname === '/bedrijfsinstellingen'} 
            />
          </nav>
          
          {/* Push logout button to bottom */}
          <div className="mt-auto pt-4">
            <button 
              onClick={handleLogout}
              className="flex items-center p-2 w-full rounded-md transition-hover"
              style={{ color: 'var(--color-text-secondary)' }}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'var(--color-hover-light)'}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
            >
              <LogOut size={20} className="mr-3" />
              <span className="font-medium">Uitloggen</span>
            </button>
          </div>
        </div>
      </div>
      
      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Top navbar - Only visible on desktop */}
        <div className="hidden md:flex shadow-sm px-4 py-2 justify-between items-center" 
             style={{ backgroundColor: 'var(--color-bg-primary)', borderBottom: '1px solid var(--color-border)' }}>
          {/* Left side - could include breadcrumbs or page title */}
          <div></div>
          
          {/* Right side - user menu and theme toggle */}
          <div className="flex items-center space-x-4">
            {/* Theme Toggle */}
            <ThemeToggle />
            
            {/* User menu */}
            <div className="relative">
              <button 
                onClick={toggleUserMenu}
                className="flex items-center space-x-2 focus:outline-none transition-hover"
                style={{ color: 'var(--color-text-primary)' }}
                onMouseOver={(e) => e.currentTarget.style.opacity = '0.8'}
                onMouseOut={(e) => e.currentTarget.style.opacity = '1'}
              >
                <div className="w-8 h-8 bg-blue-600 rounded-full flex items-center justify-center text-white">
                  <User size={18} />
                </div>
                <span className="hidden md:inline-block font-medium">{user?.name}</span>
              </button>
              
              {showUserMenu && (
                <div 
                  className="absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 z-10 border"
                  style={{ 
                    backgroundColor: 'var(--color-bg-primary)', 
                    borderColor: 'var(--color-border)'
                  }}
                >
                  <Link
                    to="/bedrijfsinstellingen"
                    className="block px-4 py-2 text-sm transition-hover"
                    style={{ color: 'var(--color-text-primary)' }}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'var(--color-hover-light)'}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                    onClick={() => setShowUserMenu(false)}
                  >
                    Bedrijfsinstellingen
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 py-2 text-sm transition-hover"
                    style={{ color: 'var(--color-text-primary)' }}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'var(--color-hover-light)'}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
                  >
                    Uitloggen
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        
        {/* Mobile theme toggle - Fixed at top right, only shows when header is hidden */}
        <div 
          className={`md:hidden fixed top-2 right-2 z-10 rounded-full p-2 shadow-md transition-opacity duration-300 ${isHeaderVisible ? 'opacity-0 pointer-events-none' : 'opacity-100'}`}
          style={{ backgroundColor: 'var(--color-bg-primary)' }}
        >
          <ThemeToggle />
        </div>
        
        {/* Main content area */}
        <div className="flex-1 overflow-auto" style={{ backgroundColor: 'var(--color-bg-secondary)' }}>
          {children}
        </div>
        
        {/* Mobile Bottom Navigation - Only visible on mobile */}
        <div className="md:hidden fixed bottom-0 left-0 right-0 border-t shadow-lg z-50" 
             style={{ 
               backgroundColor: 'var(--color-bg-primary)', 
               borderColor: 'var(--color-border)'
             }}>
          <div className="grid grid-cols-4 px-1 py-2">
            {/* Dashboard */}
            <div className="flex justify-center">
              <Link to="/" 
                    className="flex flex-col items-center py-1 border-0 outline-none" 
                    style={{ 
                      color: location.pathname === '/' ? 'var(--color-accent)' : 'var(--color-text-secondary)',
                      background: 'transparent',
                      boxShadow: 'none',
                      textShadow: 'none'
                    }}>
                <Home size={22} strokeWidth={location.pathname === '/' ? 2.5 : 2} />
                <span className="text-xs mt-1">Dashboard</span>
              </Link>
            </div>

            {/* Orders */}
            <div className="flex justify-center">
              <Link to="/inkooporders" 
                    className="flex flex-col items-center py-1 border-0 outline-none"
                    style={{ 
                      color: location.pathname.includes('/inkooporders') ? 'var(--color-accent)' : 'var(--color-text-secondary)',
                      background: 'transparent',
                      boxShadow: 'none',
                      textShadow: 'none'
                    }}>
                <FileText size={22} strokeWidth={location.pathname.includes('/inkooporders') ? 2.5 : 2} />
                <span className="text-xs mt-1">Orders</span>
              </Link>
            </div>

            {/* Klanten */}
            <div className="flex justify-center">
              <Link to="/klanten" 
                    className="flex flex-col items-center py-1 border-0 outline-none"
                    style={{ 
                      color: location.pathname.includes('/klanten') ? 'var(--color-accent)' : 'var(--color-text-secondary)',
                      background: 'transparent',
                      boxShadow: 'none',
                      textShadow: 'none'
                    }}>
                <Users size={22} strokeWidth={location.pathname.includes('/klanten') ? 2.5 : 2} />
                <span className="text-xs mt-1">Klanten</span>
              </Link>
            </div>

            {/* Instellingen */}
            <div className="flex justify-center">
              <Link to="/bedrijfsinstellingen" 
                    className="flex flex-col items-center py-1 border-0 outline-none"
                    style={{ 
                      color: location.pathname.includes('/bedrijfsinstellingen') ? 'var(--color-accent)' : 'var(--color-text-secondary)',
                      background: 'transparent',
                      boxShadow: 'none',
                      textShadow: 'none'
                    }}>
                <Settings size={22} strokeWidth={location.pathname.includes('/bedrijfsinstellingen') ? 2.5 : 2} />
                <span className="text-xs mt-1">Instellingen</span>
              </Link>
            </div>
          </div>
        </div>
        
        {/* Add padding for bottom navigation on mobile */}
        <div className="md:hidden h-16"></div>
      </div>
    </div>
  );
};

const SidebarItem = ({ to, icon, label, active = false }) => {
  return (
    <Link
      to={to}
      className="flex items-center p-2 rounded-md cursor-pointer transition-hover"
      style={active 
        ? { 
            backgroundColor: 'var(--color-accent-light)', 
            color: 'var(--color-accent)' 
          } 
        : { 
            color: 'var(--color-text-secondary)'
          }
      }
      onMouseOver={(e) => {
        if (!active) {
          e.currentTarget.style.backgroundColor = 'var(--color-hover-light)';
        }
      }}
      onMouseOut={(e) => {
        if (!active) {
          e.currentTarget.style.backgroundColor = '';
        }
      }}
    >
      {icon}
      <span className="ml-3 font-medium">{label}</span>
    </Link>
  );
};

export default Layout;